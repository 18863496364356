$theme-colors: (
  "primary": #a89968,
  "secondary":#042C47,
  "success": #00473E,
  "info": #4584AD,
  "warning":#470745,
  "danger": #94100F,
  "light": #fcfaf5,
  "dark": #303030 
);

@import "../node_modules/bootstrap/scss/bootstrap";

//happy with dark, primary, warning. 

// "primary": #a89968,
// "secondary":#042C47,
// "success": #00D4B7,
// "info": #4584AD,
// "warning":#c9734e,
// "danger": #94100F,
// "light": #fcfaf5,
// "dark": #303030 
