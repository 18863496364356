html {
  height: 100%;
}
body {
  background: rgb(0,51,44);
  background: linear-gradient(210deg, rgba(0,51,44,1) 3%, rgba(0,71,62,1) 26%, rgba(0,84,73,1) 67%, rgba(0,148,128,1) 95%, rgba(104,168,153,1) 100%);
  height: 100%;
  background-repeat: no-repeat;
    background-attachment: fixed;
  background-position: center;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.s {
  font-family: 'Newsreader', serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --amplify-primary-color: #a89968;
  --amplify-primary-tint: #e6dab2,;
  --amplify-primary-shade:#77662f;

}
/* background: rgb(0,51,44);
  background: linear-gradient(210deg, rgba(0,51,44,1) 3%, rgba(0,71,62,1) 26%, rgba(0,84,73,1) 67%, rgba(0,148,128,1) 95%, rgba(104,168,153,1) 100%);
   */